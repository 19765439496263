@use '/styles/colors.scss';
@use '/styles/variables.scss';

.sidebar {
  transition: width 0.3s;
  width: variables.$sidebarWidth;

  & > div {
    overflow: hidden;
    overflow-y: auto;
    transition: width 0.3s;
    width: variables.$sidebarWidth;
  }
}

.logo {
  display: flex;
  margin: 36px;
  padding: 2px 0 5px;
  width: 100px;

  & > img {
    display: block;
    height: auto;
    width: 100px;
  }
}

.logoCollapsed {
  display: flex;
  justify-content: center;
  margin: 36px 0;

  & > img {
    background-color: colors.$background-neutral-light;
    border-radius: 50%;
    padding: 5px;
    height: 34px;
    width: 34px;
  }
}

.divider {
  margin-top: auto;
}

.icon {
  color: colors.$icon-light;
  display: block;
}

.list {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.element {
  color: colors.$text;
  line-height: 24px;
  padding: 9px 12px;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;

  &.current {
    color: colors.$primary;
  }

  .icon {
    transition: color 0.3s;
  }

  &.active {
    color: colors.$primary;

    .icon {
      color: colors.$primary;
    }
  }

  .linkText {
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.85rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:active,
  &:focus,
  &:hover {
    color: colors.$primary;
    background-color: colors.$background-neutral-light;
    text-decoration: none;

    .icon {
      color: colors.$primary;
    }

    .linkText {
      color: colors.$primary;
    }
  }

  &:focus-visible {
    outline: 0;
  }
}

.elementIconWrapper {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 35px;
  justify-content: center;
}

.toggler {
  margin-top: auto;
  
  .icon {
    color: #FFF;
  }
}

div.backdrop {
  z-index: 1199;
}

/**
 * Collapsed sidebar
 */
 @media (min-width: 601px) {
 .collapsed {
    width: variables.$sidebarCollapsedWidth;

    & > div {
      width: variables.$sidebarCollapsedWidth;
    }

    .linkText {
      visibility: hidden;
    }

    .expandIcon {
      visibility: hidden;
    }

    .icon {
      margin-right: 0;
    }
  }
}

/**
 * Mobile
 */
 @media (max-width: 600px) {
  .mobileHidden {
    width: 0;

    & > div {
      width: 0;
    }
  }

  .toggler {
    display: none;
  }

  .elementsBottom {
    border-bottom: 0;
  }
}
