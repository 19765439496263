@use 'sass:color';

$primary: #109CF1;
$primary-disabled: #C2CFE0;

// Brand blue?: 2986C7

$secondary: #ff9800;
$tertiary: #DD4631;

$text: #334D6E;
$text-light-med: #677b96;
$text-light: #90A0B7;

$neutral-black: #192A3E;

$divider: #EBEFF2;

$background-neutral-lighter: #F5F6F8;
$background-neutral-light: #EBEFF2;
$background-neutral: #90A0B7;
$background-body: #F5F6F8;

$icon-light: #C2CFE0;