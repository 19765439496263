@use './colors.scss';
@use './fonts.scss';
@use './variables.scss';

/**
 * Base elements setup
 */
html,
body {
  background-color: colors.$background-body;
  font-family: fonts.$text;
  font-size: 15px;
  height: 100%;
  line-height: 1.6;
  padding: 0;
  margin: 0;
}

#__next {
  height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  color: colors.$primary;
  cursor: pointer;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

hr {
  border-bottom: 0;
  border-top: 1px solid colors.$divider;
}

/**
 * Layout
 */
.appWrapper {
  min-height: 100%;
  padding: 25px 25px 100px 25px;
  position: relative;
  transition: margin 0.3s;
}

body .m0 {
  margin: 0;
}

body .m1 {
  margin: 8px;
}

body .m2 {
  margin: 16px;
}

body .m4 {
  margin: 32px;
}

body .mb2 {
  margin-bottom: 16px;
}

body .mb4 {
  margin-bottom: 32px;
}

body .mr2 {
  margin-right: 16px;
}

body .my2 {
  margin-bottom: 16px;
  margin-top: 16px;
}

body .mx2 {
  margin-left: 16px;
  margin-right: 16px;
}

body .mx4 {
  margin-left: 32px;
  margin-right: 32px;
}

body .nm4 {
  margin: -32px;
}

body .nmx4 {
  margin-left: -32px;
  margin-right: -32px;
}

body .p0 {
  padding: 0;
}

body .p1 {
  padding: 8px;
}

body .p2 {
  padding: 16px;
}

body .p4 {
  padding: 32px;
}

body .px2 {
  padding-left: 16px;
  padding-right: 16px;
}

body .px4 {
  padding-left: 32px;
  padding-right: 32px;
}

body .pt2 {
  padding-top: 32px;
}

body .pt4 {
  padding-top: 32px;
}

body .pb2 {
  padding-bottom: 16px;
}

body .pb4 {
  padding-bottom: 32px;
}

body .alignRight,
body .alignRight input {
  text-align: right;
}

body .justifyEnd {
  justify-content: flex-end;
}

body .MuiAlert-root {
  align-items: center;
}

body .MuiAlert-message {
  flex-grow: 2;
}

/**
 * Material-UI overrides
 */
nav.breadcrumbs {
  border-bottom: 1px solid colors.$divider;
  margin-bottom: 15px;
  padding-bottom: 15px;

  .MuiBreadcrumbs-ol {
    align-items: center;
  }

  .MuiBreadcrumbs-li {
    max-width: 30%; // Mainly for mobile
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .MuiBreadcrumbs-li:last-child {
    p {
      font-size: 1.5rem;
    }

    span {
      display: none;
      vertical-align: middle;
    }

    &:hover {
      span {
        display: inline-flex;
      }
    }
  }
}

body .MuiTableCell-head {
  color: colors.$text-light;
}

body .MuiFormLabel-root {
  color: colors.$text;
}

.cardPrimary {
  border-left: 5px solid colors.$primary;
}

.cardSecondary {
  border-left: 5px solid colors.$secondary;
}

.cardTertiary {
  border-left: 5px solid colors.$tertiary;
}

#mui-rte-container>div:nth-child(2) {
  padding: 8px;
  position: static;
}

.MuiOutlinedInput-root {
  background-color: #FFF;
}

.MuiTablePagination-toolbar {
  overflow: auto;
}

/**
 * Utilities
 */
.bgNeutralLight {
  background-color: colors.$background-neutral-light;
}

.borderBottom {
  border-bottom: 1px solid colors.$divider;
}

.textNote {
  color: colors.$text-light-med;
  font-style: italic;
  font-size: 0.9rem;
}

.tableContrastRow {
  td {
    background-color: colors.$background-neutral-light;
    //color: #FFF;
  }
}

table.zebra {
  tbody tr:nth-of-type(odd) {
    background-color: colors.$background-neutral-light;
  }
}

body .titleLabel {
  color: colors.$neutral-black;
  display: block;
  font-size: 1.2rem;
  line-height: 1.4;
}

hr.paperSeparator {
  margin: 32px -32px;

  &.tight {
    margin: 8px -32px;
  }
}

body .hidden {
  display: none;
}

.sourceIcon {
  height: auto;
  max-width: none;
  width: 25px;
}

.responsiveIframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

// Used for inputs/selects
.narrow {
  max-width: 100%;
  width: 300px;
}

.veryNarrow {
  max-width: 100%;
  width: 150px;
}

.withSelectAdornment {

  // Input wrapper - End
  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }

  // Input wrapper - Start
  .MuiInputBase-adornedStart {
    padding-left: 0;
  }

  // Adornment
  .MuiInputAdornment-root {
    margin-left: 0;

    // To move into the adornment system
    .MuiFormControl-root,
    .MuiInput-root {
      width: 100%;
    }

    .MuiInput-root,
    .MuiAutocomplete-root {

      &::before {
        border: 0;
      }

      .MuiInput-input {
        padding: 16.5px 14px;
      }

      .MuiSelect-select {
        padding: 16.5px 32px 16.5px 14px;
      }

      .MuiSvgIcon-root {
        right: 7px;
      }

      &.MuiInputBase-sizeSmall {
        .MuiInput-input {
          padding: 8px 14px;
        }

        .MuiSelect-select {
          padding: 8px 32px 8px 14px;
        }

      }
    }
  }

  // Autocomplete
  &.MuiAutocomplete-root {
    display: inline;
  }

  .MuiInputBase-inputAdornedEnd {
    text-align: right;
    padding-right: 14px;
  }

  // Adornment
  .MuiInputAdornment-root {
    max-width: 50%;
    text-align: left;
  }

  // Adornment - End
  .MuiInputAdornment-positionEnd {

    .MuiInput-root,
    .MuiAutocomplete-root {

      .MuiSelect-select,
      .MuiInput-input {
        border-left: 1px solid colors.$divider;
      }

    }
  }

  // Adornment - Start
  .MuiInputAdornment-positionStart {

    .MuiInput-root,
    .MuiAutocomplete-root {

      .MuiSelect-select,
      .MuiInput-input {
        border-right: 1px solid colors.$divider;
      }

    }
  }

}

.quantityField {
  max-width: 100%;
  width: 300px;

  &.small {
    width: 150px;
  }

  // Adornment
  .MuiInputAdornment-root {
    flex-shrink: 0;
    width: 150px;
  }

}

.withAdornment {

  // Input
  .MuiInputBase-inputAdornedEnd {
    text-align: right;
    padding-right: 7px;
  }
}

.appFooter {
  align-items: center;
  border-top: 1px solid #DDD;
  bottom: 0;
  display: flex;
  font-size: 0.85rem;
  left: 0;
  right: 0;
  padding: 20px;
  position: absolute;

  .leftPart {
    margin-right: auto;
  }

  .rightPart {
    margin-left: auto;
  }

  .footerLogo {
    opacity: 0.5;
    transition: opacity 0.5s;

    &:active,
    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  a {
    color: colors.$text-light;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      color: colors.$text;
    }
  }
}

.coloredChip {

  // Force delete icon to inherit text color
  .MuiSvgIcon-root {
    color: inherit;
    opacity: 0.3;

    &:hover {
      color: inherit;
      opacity: 0.5;
    }
  }
}

/**
 * Responsive adjustments
 */
@media (min-width:601px) {

  .appWrapper {
    margin-left: variables.$sidebarWidth;

    &.sidebarCollapsed {
      margin-left: variables.$sidebarCollapsedWidth;
    }

    &.sidebarHidden {
      margin-left: 0;
    }
  }

}

// Responsive table
@media (max-width:600px) {
  .tableScrollWrapper {
    overflow: auto;
  }

  table.responsiveBlock {
    display: block;

    thead,
    tbody,
    tr,
    th,
    td {
      display: block;
      width: 100%;
    }
  }

  table.responsiveFlex {
    display: block;

    thead,
    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
    }

    th,
    td {
      display: block;
      flex-grow: 1;
    }
  }

  .appWrapper {
    padding-bottom: 0;
  }

  .appFooter {
    flex-direction: column;
    margin: 25px -25px 0;
    position: initial;

    .leftPart,
    .rightPart {
      margin: 8px auto;
      order: 1;
      text-align: center;
    }
  }
}


#previewWrapper {
  [data-can-field]:hover {
    cursor: pointer;

    color: colors.$primary;

    * {
      color: colors.$primary;
    }
  }
}

.scroll-shadows {
  overflow: auto;

  background:
    /* Shadow Cover TOP */
    linear-gradient(white 30%,
      rgba(255, 255, 255, 0)) center top,

    /* Shadow Cover BOTTOM */
    linear-gradient(rgba(255, 255, 255, 0),
      white 70%) center bottom,

    /* Shadow TOP */
    radial-gradient(farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)) center top,

    /* Shadow BOTTOM */
    radial-gradient(farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}