@use '/styles/colors.scss';

$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';

@import '~material-design-icons-iconfont/src/material-design-icons';

.icon {
  @extend .material-icons;
}

.ellipsis {
  background-color: colors.$icon-light;
  border-radius: 50%;
  color: #FFFFFF;
}

a:active .ellipsis,
a:hover .ellipsis,
a:focus .ellipsis {
  background-color: colors.$primary;
}